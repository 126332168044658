import React from "react";
import styles from "./Projects.module.css";
import { Link } from "react-router-dom";

export const Projects = () => {
  return (
    <div className={styles.projContainer}>
      <div className={styles.cardContainer}>
        <a href="https://workout-tracker-emanuel.herokuapp.com/">
          <img
            src={process.env.PUBLIC_URL + "/imgs/workout.png"}
            alt="workout"
          />
        </a>
        <a
          className={styles.title}
          href="https://workout-tracker-emanuel.herokuapp.com/"
        >
          MERN Stack Workout Tracker
        </a>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/weight-tracker"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <a href="https://abletonaboutpagemock.netlify.app/">
          <img
            src={process.env.PUBLIC_URL + "/imgs/ableton.png"}
            alt="ableton"
          />
        </a>
        <a
          className={styles.title}
          href="https://abletonaboutpagemock.netlify.app/"
        >
          Mock Ableton About Page
        </a>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/mock-sites"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <a href="https://chessaidemo.netlify.app/">
          <img
            src={process.env.PUBLIC_URL + "/imgs/chess.png"}
            alt="chess ai"
          />
        </a>
        <a className={styles.title} href="https://chessaidemo.netlify.app/">
          BFS Chess AI
        </a>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/react-portfolio/tree/main/chess"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <Link to="/projects/PokemonProject">
          <img
            src={process.env.PUBLIC_URL + "/imgs/pokemon.png"}
            alt="pokemone"
          />
        </Link>
        <Link className={styles.title} to="/projects/PokemonProject">
          Pokemon Type Filter
        </Link>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/react-portfolio/tree/main/pokemone-type-filter"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <a href="https://rgbgamedemo.netlify.app/">
          <img
            src={process.env.PUBLIC_URL + "/imgs/rgbgame.png"}
            alt="chess ai"
          />
        </a>
        <a className={styles.title} href="https://rgbgamedemo.netlify.app/">
          RGB Guessing Game
        </a>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/react-portfolio/tree/main/rgb-game"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <Link to="/projects/WordleProject">
          <img
            src={process.env.PUBLIC_URL + "/imgs/wordle.png"}
            alt="wordle project"
          />
        </Link>
        <Link className={styles.title} to="/projects/WordleProject">
          Computer Science Wordle
        </Link>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/react-portfolio/tree/main/wordle"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <a href="https://stand-up-rails-app.herokuapp.com/">
          <img
            src={process.env.PUBLIC_URL + "/imgs/openMic.png"}
            alt="stand up comedy web app"
          />
        </a>
        <a
          className={styles.title}
          href="https://stand-up-rails-app.herokuapp.com/"
        >
          Stand-Up Comedy Events Site
        </a>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/stand-up-rails-app/tree/main/open-mic-events"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>

      <div className={styles.cardContainer}>
        <Link to="/projects/TimesTablesProject">
          <img
            src={process.env.PUBLIC_URL + "/imgs/timesTables.png"}
            alt="times tables learner"
          />
        </Link>
        <Link className={styles.title} to="/projects/TimesTablesProject">
          Times Tables Learner
        </Link>
        <a
          className={styles.btn}
          href="https://github.com/emanuelhuffman/react-portfolio/tree/main/times-tables-learner"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>
    </div>
  );
};

export default Projects;
