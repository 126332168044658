import "./App.css";
import Nav from "./components/Nav/Nav";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PokemonProject from "./components/Projects/PokemonProject/PokemonProject";
import CalculatorProject from "./components/Projects/CalculatorProject/CalculatorProject";
import TimesTablesProject from "./components/Projects/TimesTablesProject/TimesTablesProject";
import WordleProject from "./components/Projects/WordleProject/WordleProject";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <div className="container">
                <Nav />
                <About />
              </div>
            }
          />
          <Route
            path="/projects"
            element={
              <div className="container">
                <Nav />
                <Projects />
              </div>
            }
          />
          <Route
            path="/contact"
            element={
              <div className="container">
                <Nav />
                <Contact />
              </div>
            }
          />

          {/* Project Routes */}
          <Route
            path="/projects/PokemonProject"
            element={
              <div className="pokemonContainer">
                <PokemonProject />
              </div>
            }
          />
          <Route
            path="/projects/CalculatorProject"
            element={
              <div className="calculatorContainer">
                <CalculatorProject />
              </div>
            }
          />
          <Route
            path="/projects/TimesTablesProject"
            element={
              <div className="timesTablesContainer">
                <TimesTablesProject />
              </div>
            }
          />

          <Route
            path="/projects/WordleProject"
            element={
              <div className="wordleContainer">
                <div>
                  <WordleProject />
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
