import React from "react";
import styles from "./Contact.module.css";
import emailjs from "emailjs-com";
import { useState } from "react";

export const Contact = () => {
  const [name, setName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qnv0yrf",
        "template_9r4z2np",
        e.target,
        "PelOJ3BLbN7I3V3a9"
      )
      .then((res) => {
        setName("");
        setUserEmail("");
        setMessage("");
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.card}>
      <h3 className={styles.contactText}>Contact</h3>
      {success && <div className={styles.success}>Message Sent!</div>}
      <form onSubmit={sendEmail}>
        <input
          type="text"
          name="name"
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          name="userEmail"
          value={userEmail}
          placeholder="Email"
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <textarea
          name="message"
          rows="4"
          value={message}
          placeholder="Message"
          onChange={(e) => setMessage(e.target.value)}
        />

        <button>Submit</button>
      </form>
    </div>
  );
};

export default Contact;
