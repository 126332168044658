import React from "react";
import styles from "./About.module.css";
import portrait from "./portrait.jpg";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";

export const About = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.blurb}>
          <h1>Hi, I'm Emanuel</h1>
          <h3 className={styles.intro}>
            I'm a software developer located in Saint Louis, Missouri. You've
            landed on my portofolio site. Take a look at the projects page to
            view my work. I tend to do a lot of web development. If you want to
            reach out, head over to the contact page.
          </h3>
          <h3>Thanks for visiting,</h3>
          <h4 className={styles.signature}>-Emanuel Huffman</h4>
        </div>
        <div className={styles.portrait}>
          <img src={portrait} alt="portrait picture" />
        </div>
      </div>
      <br />
      <br />
      <Projects />
    </div>
  );
};

export default About;
