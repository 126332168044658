exports.words = [
  "abort",
  "apple",
  "arial",
  "ascii",
  "basic",
  "block",
  "board",
  "cache",
  "click",
  "cobol",
  "coral",
  "crash",
  "debug",
  "email",
  "erase",
  "excel",
  "field",
  "flash",
  "frame",
  "gates",
  "image",
  "input",
  "intel",
  "layer",
  "logic",
  "login",
  "logon",
  "macro",
  "micro",
  "modem",
  "mouse",
  "panel",
  "patch",
  "pixel",
  "proxy",
  "purge",
  "query",
  "queue",
  "reset",
  "robot",
  "stack",
  "spool",
  "stats",
  "table",
  "track",
  "tweet",
  "virus",
  "write",
  "paste",
  "shell",
];
